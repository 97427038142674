import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AsyncSelect from 'react-select/async';

const bear = new URL("img/bear.png", import.meta.url);
const nazibear = new URL("img/nazibear.png", import.meta.url);
const sep = new URL("img/separator.png", import.meta.url);

export function App() {
    const [inv, setInv] = useState([])
    const [sel, setSel] = useState([])
    const [reason, setReason] = useState("")
    const [choice, setChoice] = useState()
    const [clicks, setClicks] = useState(0)
    useEffect(() => {
        if (inv.length == 0) {
            fetch("api.php?action=list")
                .then(res => res.json())
                .then(data => {
                    if (data)
                        setInv(data)
                }).catch(err => Swal.fire("Ops, qualcosa è andato storto!", "Chiama Michele..."));
        }
    }, [])
    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(inputValue.length > 2 ? inv.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())) : []);
            }, 1000);
        });
    function send () {
        if (sel.length > 0) {
            Swal.fire("Attendi...", "Stiamo comunicando le partecipazioni...");
            Swal.showLoading();
            fetch("api.php?action=presence", {
                method: "POST",
                body: JSON.stringify({ sel, choice, reason })
            }).then(res => res.json())
                .then(data => {
                    if (data.ok) {
                        Swal.fire("Grazie!", `Hai confermato la tua <strong>${(choice ? "PARTECIPAZIONE" : "DISERZIONE")}</strong> all'evento!`, "success")
                        setChoice(null);
                        setSel([]);
                        setReason("");
                    } else throw new Error();
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire("Ops, qualcosa è andato storto!", "Riprova o chiama Michele!", "warning");
                })
        } else
            Swal.fire("Non hai selezionato alcun invitato!", "", "warning")
    }
    return (
        <div className={"container" + (clicks > 5 ? " font-nazi" : "")}>
            <div className="py-5 px-4 text-center">
                <div>
                    <img src={clicks > 5 ? nazibear : bear} className="img-fluid m-auto" onClick={() => setClicks(clicks + 1)} />
                </div>
                <div className="row g-3 text-center mb-3">
                    <div className="col-6">
                        <div className="fs-3">DOMENICA</div>
                        <div className="dataora">03/09</div>
                    </div>
                    <div className="col-6">
                        <div className="dataora">11:30</div>
                        <div className="fs-3">ORE</div>
                    </div>
                </div>
                
                <div>La cerimonia si terrà alla</div>
                <div>
                    <h2 className="font-pacifico mb-3">Chiesa di San Giovanni Battista</h2>
                    <a className="btn btn-primary text-white rounded-pill" href="https://www.google.com/maps/dir/43.0283579,12.3234839/chiesa+di+pila+perugia/@43.0390916,12.304796,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x132ea6a477008bc7:0xd77031e0ec6a3ee4!2m2!1d12.3292636!2d43.0531375?entry=ttu">
                        <i className="bi bi-geo-alt-fill"></i> Via del Moggio, 06132 Pila PG
                    </a>
                </div>
                <div className="separatore my-4">
                    <img src={sep} className="img-fluid" />
                </div>
                <div>dopo la cerimonia saremo felici di festeggiare insieme all'</div>
                <div>
                    <h2 className="font-pacifico mb-3">Abbazia Settefrati - Agriturismo Fratres</h2>
                    <a className="btn btn-primary text-white rounded-pill" href="https://www.google.com/maps/dir//abbazia+sette+frati/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x132eae2c0be3b679:0x8300db52e746b13c?sa=X&ved=2ahUKEwinkrnmxYmAAxUmywIHHagZDb8Q9Rd6BAhDEAA&ved=2ahUKEwinkrnmxYmAAxUmywIHHagZDb8Q9Rd6BAhSEAQ">
                        <i className="bi bi-geo-alt-fill"></i> Voc. Abbadia Settefrati, 49a, 06066 Piegaro PG
                    </a>
                </div>
                <div className="separatore my-4">
                    <img src={sep} className="img-fluid" />
                </div>
                <div className="mb-3">Conferma subito la tua presenza!</div>
                {choice == null ? (
                    <div className="d-flex flex-column flex-md-row justify-content-center">
                        <button className="btn btn-lg btn-success mb-3 mb-md-0 me-md-3" onClick={() => setChoice(true)}>Ci sarò! <i className="bi bi-emoji-sunglasses-fill"></i></button>
                        <button className="btn btn-lg btn-danger" onClick={() => setChoice(false)}>Non posso <i className="bi bi-emoji-frown-fill"></i></button>
                    </div>
                ) : (
                    <div>
                        <h5>Seleziona uno o più nomi</h5>
                        <div className="mb-3">
                            <AsyncSelect defaultValue={sel} onChange={(vals) => setSel(vals)} placeholder="Scrivi il tuo nome" isMulti loadOptions={promiseOptions}
                                loadingMessage={() => <>Ricerca in corso <i className="bi bi-search"></i></>} 
                                noOptionsMessage={() => <>Nessun risultato trovato <i className="bi bi-emoji-frown-fill"></i></>} />
                        </div>
                        <hr />
                        <h5>Se non riesci a trovare il tuo nome le possibili cause sono:</h5>
                        <ul className="list-group list-group-numbered text-start mb-3">
                            <li className="list-group-item">Non sei invitato <i className="bi bi-emoji-neutral-fill"></i></li>
                            <li className="list-group-item">Ci siamo scordati di aggiungerti <i className="bi bi-heartbreak-fill"></i></li>
                            <li className="list-group-item">E' un bug <i className="bi bi-bug-fill"></i></li>
                        </ul>
                        <h5>In ogni caso, <strong>se non sei in lista ma reputi necessaria la tua partecipazione all'evento</strong>, ti preghiamo di chiamarci!</h5>
                        <hr />
                        <div className="mb-3">
                            {choice == true ? (
                                <label className="form-label">Hai <strong>allergie</strong> o qualcosa che dovrebbe sapere lo chef?</label>
                            ) : (
                                <label className="form-label">Dacci un motivo (se vuoi)!</label>
                            )}
                            <textarea value={reason} className="form-control" onChange={(evt) => setReason(evt.target.value)}></textarea>
                        </div>
                        
                        <button className={`btn btn-${choice ? "success" : "danger"} text-light me-3`} onClick={send}>Conferma {choice ? "partecipanti" : "disertori"}</button>
                        <button className="btn btn-secondary" onClick={() => setChoice(null)}>Annulla</button>
                    </div>
                )}
                <div className="separatore my-4">
                    <img src={sep} className="img-fluid" />
                </div>
                <div>Gioele ha lasciato la sua letterina dei desideri presso Universo Bimbo a Ellera di Corciano!</div>
                <div>
                    <h2 className="font-pacifico mb-3">Lista nascita - Universo Bimbo</h2>
                    <a className="btn btn-primary text-white rounded-pill" href="https://www.google.com/maps/place/I+Bimbo+Ellera+Umbra/@43.1001053,12.3126535,17z/data=!3m1!4b1!4m20!1m13!4m12!1m4!2m2!1d12.4265958!2d42.9883509!4e1!1m6!1m2!1s0x132ea711a124c927:0xb933fd577ec20cda!2suniverso+bimbo+ellera!2m2!1d12.3152284!2d43.1001014!3m5!1s0x132ea711a124c927:0xb933fd577ec20cda!8m2!3d43.1001014!4d12.3152284!16s%2Fg%2F11bw64zzww?entry=ttu">
                        <i className="bi bi-geo-alt-fill"></i> Via Pierluigi Nervi, 7, 06073 Corciano PG
                    </a>
                </div>
                <div className="separatore my-4">
                    <img src={sep} className="img-fluid" />
                </div>
                <div>Desirée <i className="bi bi-phone-fill"></i> 3386594258</div>
                <div>Michele <i className="bi bi-phone-fill"></i> 3331330532</div>
                <div>Gioele <i className="bi bi-phone-fill"></i> è ancora un pò presto</div>
            </div>
        </div>
    )
}